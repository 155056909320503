import React from 'react';
import {
  Container, HeaderContainer, ChildrenContainer, HeaderTitle,
} from './styles';

export default function CardComponent({ title, children }) {
  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
}
