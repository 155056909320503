import { combineReducers } from 'redux';

import { reducer as clients } from './clients';
import { reducer as navigation } from './navigation';

const reducers = combineReducers({
  clients,
  navigation,
});

export default reducers;
