import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: ${colors.secondary};
  background: #F5F5F5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  height: 20%;

  background: ${colors.primary};
`;

export const HeaderLogo = styled.img`
  width: 20%;
  height: auto;
  resize: none;
  margin-left: 30px;

  @media (max-width: 320px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 600px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 801px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 1025px) {
    width: 60%;
    margin-left: 2%;
  }

`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px;
`;

export const PlansTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 10px;
`;

export const PlansSubContainer = styled.div`
  padding: 0 30px;
  margin-top: 25px;
  width: 100%;
  @media (max-width: 320px) {
    padding: 0;
  }

  @media (max-width: 480px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 801px) {
    padding: 0;
  }

  @media (max-width: 1025px) {
    padding: 0;
  }
`;

export const PlansSub = styled.p`
  font-size: 12px;
  color: #555;
  line-height: 22px;
  text-align: left;
  margin-bottom: 10px;

  @media (max-width: 320px) {
    padding: 0;
  }

  @media (max-width: 480px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: 801px) {
    padding: 0;
  }

  @media (max-width: 1025px) {
    padding: 0;
  }
`;

export const PlansImage = styled.img`
  width: 60%;
  height: auto;
  resize: none;

  @media (max-width: 320px) {
    width: 300px;
  }

  @media (max-width: 480px) {
    width: 300px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 801px) {
    width: 100%;
  }

  @media (max-width: 1025px) {
    width: 100%;
  }

`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 30px;
`;
