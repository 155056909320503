import styled from 'styled-components';
import colors from '../../styles/colors';

export const ContainerPlans = styled.div`
  width: 90%;
  padding: 20px;
  background: white;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid rgb(231, 231, 231);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: border-color 300ms;

  a{
    width: 70%;
    height: 40px;
  }

  &:hover {
    border-color: ${colors.primary};
  }

  @media (max-width: 320px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 801px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const ContainerText = styled.div`
  width: 80%;
  height: 100%;

    @media (max-width: 320px) {
      width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 801px) {
    width: 100%;
  }
`;

export const ContainerAction = styled.div`
  width: 20%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

    @media (max-width: 320px) {
      width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 801px) {
    width: 100%;
  }
`;

export const PlansTitle = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
  letter-spacing: 2px;
`;

export const PlansDescriptionList = styled.ul`
  padding: 20px;
`;

export const PlansDescription = styled.li`
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
  color: #555;
`;

export const PlansPrice = styled.span`
    font-size: 31px;
    color: ${colors.primary};
    font-weight: 700;

    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PlansPricePeriod = styled.h2`
    font-size: 20px;
    color: ${colors.secondary};
    font-weight: 500;
`;

export const PlansPriceDesc = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const PlansButton = styled.button`
  font-size: 16px;
  width: 60%;
  height: 40px;
  color: white;
  border: none;
  border-radius: 5px;
  background: ${colors.primary};
  transition: background 300ms;

  &:hover {
    background: #008878;
  }
`;

export const RowCheck = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const CheckInput = styled.input``;

export const LabelCheckInput = styled.label`
  margin-right: 10px;
`;
