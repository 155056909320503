import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  HeaderContainer,
  HeaderLogo,
  PlansContainer,
  PlansTitle,
  PlansImage,
  PlansSub,
  PlansSubContainer,
  MainContainer,
} from './styles';

import PlansData from '../../utils/plans';
import FiliaisData from '../../filiais';

import LogoImg from '../../assets/image/logo.png';
import StepsImg from '../../assets/image/steps.png';
import PlansRender from '../../components/PlansRender';

export default function Plans({ history }) {
  const { cidade } = useParams();
  const [filialData, setFilialData] = useState(null);

  useEffect(() => {
    if (cidade === '404') return history.push('/404');
    const selectFilial = FiliaisData.filter((filial) => filial.city === cidade)[0];

    if (!selectFilial) return history.push('/404');

    sessionStorage.setItem('cidade', cidade);

    return setFilialData(selectFilial);
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <HeaderLogo src={LogoImg} alt="Logo" />
      </HeaderContainer>
      {filialData && (
      <PlansContainer>
        <PlansTitle>Planos</PlansTitle>
        <PlansImage src={StepsImg} alt="plans steps" />
        <PlansSubContainer>
          <PlansSub>
            * O pagamento das mensalidades pode ser feito de formasegura por
            boleto ou cartão de crédito sem que comprometa o limite
            do cartão(cobrança recorrente);
          </PlansSub>
          <PlansSub>
            * O aderente pode cancelar o plano a qualquer hora,
            sem aincidência de multa, conforme Art 3º, parágrafo 3º do contrato de
            adesãomostrado na página de pagamento;
          </PlansSub>
          <PlansSub>
            * O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,
            CNPJ61.074.175/0001-38. O capital segurado será pago de uma única
            vez e serácalculado após a regulação do sinistro. O sac da Mapfre
            é 0800 775 7196. Verdetalhes no contrato digital no próximo passo.
          </PlansSub>
          <PlansSub>
            * Será cobrado uma taxa de ativação de R$ 50,00 (cinquentareais) para
            qualquer plano escolhido. No plano individual plus o prêmio mensaldo
            seguro é de R$ 9,50 (nove reais e cinquenta centavos). Nos planos
            Família 2Plus, Família 3 Plus, Família 4 Plus e Família 5 Plus,
            o prêmio mensal doseguro é de R$ 7,35
            (sete reais e trinta e cinco centavos) por cada membro dafamília.
            Tais valores já estão inclusos na assinatura de cada plano,
            de acordocom o plano escolhido.
          </PlansSub>
          <PlansSub>
            * Ver regras e prazos de franquia do seguro no contratodigital
            no passo 3, parágrafo 6.
          </PlansSub>
          <PlansSub>
            * O Seguro é válido para pessoas de 16 a 70 anos.
          </PlansSub>
          <PlansSub>
            * As consultas e exames sãorealizados pela clínica conveniada oficial
            para
            {' '}
            {filialData.data.city}
            {' '}
            -
            {' '}
            {filialData.data.name}
            {' '}
            -
            {' '}
            {filialData.data.address}
            .
          </PlansSub>
          <PlansSub>
            <a href="https://www.atendja.com.br/unidades/" target="_blank">
              * Consulte aqui os preços das consultas.
            </a>
          </PlansSub>
        </PlansSubContainer>
      </PlansContainer>
      )}
      {filialData && (
      <MainContainer>
        {filialData.data.plans.map((plans) => <PlansRender dataPlans={plans} filial={cidade} />)}
      </MainContainer>
      )}
    </Container>
  );
}
