import francoDaRocha from './francoDaRocha';
import hortolandia from './hortolandia';
import londrina from './londrina';
import marica from './marica';
import assis from './assis';
import arapongas from './arapongas';

export default [
  { city: 'franco-da-rocha', data: francoDaRocha },
  { city: 'hortolandia', data: hortolandia },
  { city: 'londrina', data: londrina },
  { city: 'marica', data: marica },
  { city: 'assis', data: assis },
  { city: 'arapongas', data: arapongas },
];
