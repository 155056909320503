import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  background: #fff;

  p {
    margin-bottom: 35px;
    font-size: 0.85em;
  }
`;
