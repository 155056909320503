import React from 'react';
import {
  Container, HeaderContainer, HeaderLogo, MainContainer,
} from './styles';

import LogoImg from '../../assets/image/logo.png';
import CheckImg from '../../assets/image/404.png';

export default function Page404() {
  return (
    <Container>
      <HeaderContainer>
        <HeaderLogo src={LogoImg} alt="Logo" />
      </HeaderContainer>
      <MainContainer>
        <h3>Ops...</h3>
        <img src={CheckImg} alt="check icon" />
        <p>
          Cidade não encontrada
          <br />
          Verifique a cidade e tente novamente
        </p>
        <a href="https://www.vittamedclub.com.br/unidades">Voltar para cidades</a>
      </MainContainer>
    </Container>
  );
}
