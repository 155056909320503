import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: ${colors.secondary};
  background: #F5F5F5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  height: 20%;

  background: ${colors.primary};
`;

export const HeaderLogo = styled.img`
  width: 20%;
  height: auto;
  resize: none;
  margin-left: 30px;

  @media (max-width: 320px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 600px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 801px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 1025px) {
    width: 60%;
    margin-left: 2%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;

  h3 {
    font-size: 5em;
    color: ${colors.primary};
    margin-bottom: 25px;
  }

  p {
    font-size: 2em;
    color: ${colors.secondary};
    margin-top: 35px;
    text-align: center;
  }

  img {
    height: 15%;
    width: 15%;
  }

  a {
    margin-top: 25px;
    background: ${colors.primary};
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.4em;
    font-weight: 700;
    padding: 10px 20px;
    text-decoration: none;
  }
`;
