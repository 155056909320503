export default [
  {
    internalName: 'visa',
    type: 'visa',
    name: 'Visa',
  },
  {
    internalName: 'mastercard',
    type: 'mastercard',
    name: 'MasterCard',
  },
  {
    internalName: 'diners',
    type: 'diners-club',
    name: 'Diners',
  },
  {
    internalName: 'discover',
    type: 'discover',
    name: 'Discover',
  },
  {
    internalName: 'elo',
    type: 'elo',
    name: 'Elo',
  },
  {
    internalName: 'amex',
    type: 'amex',
    name: 'Amex',
  },
  {
    internalName: 'aura',
    type: 'aura',
    name: 'Aura',
  },
  {
    internalName: 'hipercard',
    type: 'hipercard',
    name: 'Hipercard',
  },
  {
    internalName: 'jcb',
    type: 'jcb',
    name: 'JCB',
  },
];
