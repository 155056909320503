import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
`;

export const HeaderContainer = styled.div`
  padding: 10px 20px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: ${colors.primary};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const HeaderTitle = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
`;

export const ChildrenContainer = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
