import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ContainerPlans,
  ContainerAction,
  ContainerText,
  PlansButton,
  PlansDescriptionList,
  PlansDescription,
  PlansPrice,
  PlansPriceDesc,
  PlansTitle,
  PlansPricePeriod,
  CheckInput,
  LabelCheckInput,
  RowCheck,
} from './styles';

export default function PlansRender({ dataPlans, filial }) {
  const [insuranceStatus, setInsuranceStatus] = useState(false);

  return (
    <>
      <ContainerPlans>
        <ContainerText>
          <PlansTitle>{dataPlans.withoutInsurance.title}</PlansTitle>
          <PlansDescriptionList>
            {dataPlans.withoutInsurance.description.map((desc, index) => (
              <PlansDescription key={index}>{desc}</PlansDescription>
            ))}
          </PlansDescriptionList>
        </ContainerText>
        <ContainerAction>
          <PlansPrice>
            R$
            {' '}
            {dataPlans.withoutInsurance.price.toFixed(2)}
            {' '}
            <PlansPricePeriod> /Mês</PlansPricePeriod>
          </PlansPrice>
          <PlansPriceDesc>
            {dataPlans.withoutInsurance.priceDesc}
            {' '}

          </PlansPriceDesc>
          <Link to={`/assinar-planos/${filial}/${dataPlans.planName}/withoutInsurance`}>
            <PlansButton>Assinar plano</PlansButton>
          </Link>
        </ContainerAction>
      </ContainerPlans>

      <ContainerPlans>
        <ContainerText>
          <PlansTitle>{dataPlans.insurance.title}</PlansTitle>
          <PlansDescriptionList>
            {dataPlans.insurance.description.map((desc, index) => (
              <PlansDescription key={index}>{desc}</PlansDescription>
            ))}
          </PlansDescriptionList>
        </ContainerText>
        <ContainerAction>
          <PlansPrice>
            R$
            {' '}
            {dataPlans.insurance.price.toFixed(2)}
            {' '}
            <PlansPricePeriod> /Mês</PlansPricePeriod>
          </PlansPrice>
          <PlansPriceDesc>
            {dataPlans.insurance.priceDesc}
            {' '}

          </PlansPriceDesc>
          {/* <RowCheck>
          <CheckInput
            type="checkbox"
            id="insurance"
            checked={insuranceStatus}
            onChange={() => setInsuranceStatus(!insuranceStatus)}
          />
          <LabelCheckInput>Incluir seguro</LabelCheckInput>
        </RowCheck> */}
          <Link to={`/assinar-planos/${filial}/${dataPlans.planName}/insurance`}>
            <PlansButton>Assinar plano</PlansButton>
          </Link>
        </ContainerAction>
      </ContainerPlans>
    </>
  );
  // return insuranceStatus ? (
  //   <ContainerPlans>
  //     <ContainerText>
  //       <PlansTitle>{dataPlans.insurance.title}</PlansTitle>
  //       <PlansDescriptionList>
  //         {dataPlans.insurance.description.map((desc, index) => (
  //           <PlansDescription key={index}>{desc}</PlansDescription>
  //         ))}
  //       </PlansDescriptionList>
  //     </ContainerText>
  //     <ContainerAction>
  //       <PlansPrice>
  //         R$
  //         {' '}
  //         {dataPlans.insurance.price.toFixed(2)}
  //         {' '}
  //         <PlansPricePeriod> /Mês</PlansPricePeriod>
  //       </PlansPrice>
  //       <PlansPriceDesc>
  //         {dataPlans.insurance.priceDesc}
  //         {' '}

  //       </PlansPriceDesc>
  //       <RowCheck>
  //         <CheckInput
  //           type="checkbox"
  //           id="insurance"
  //           checked={insuranceStatus}
  //           onChange={() => setInsuranceStatus(!insuranceStatus)}
  //         />
  //         <LabelCheckInput>Incluir seguro</LabelCheckInput>
  //       </RowCheck>
  //       <Link to={`/assinar-planos/${dataPlans.planName}/insurance`}>
  //         <PlansButton>Assinar plano</PlansButton>
  //       </Link>
  //     </ContainerAction>
  //   </ContainerPlans>
  // ) : (
  //   <ContainerPlans>
  //     <ContainerText>
  //       <PlansTitle>{dataPlans.withoutInsurance.title}</PlansTitle>
  //       <PlansDescriptionList>
  //         {dataPlans.withoutInsurance.description.map((desc, index) => (
  //           <PlansDescription key={index}>{desc}</PlansDescription>
  //         ))}
  //       </PlansDescriptionList>
  //     </ContainerText>
  //     <ContainerAction>
  //       <PlansPrice>
  //         R$
  //         {' '}
  //         {dataPlans.withoutInsurance.price.toFixed(2)}
  //         {' '}
  //         <PlansPricePeriod> /Mês</PlansPricePeriod>
  //       </PlansPrice>
  //       <PlansPriceDesc>
  //         {dataPlans.withoutInsurance.priceDesc}
  //         {' '}

  //       </PlansPriceDesc>
  //       <RowCheck>
  //         <CheckInput
  //           type="checkbox"
  //           id="insurance"
  //           checked={insuranceStatus}
  //           onChange={() => setInsuranceStatus(!insuranceStatus)}
  //         />
  //         <LabelCheckInput>Incluir seguro</LabelCheckInput>
  //       </RowCheck>
  //       <Link to={`/assinar-planos/${dataPlans.planName}/withoutInsurance`}>
  //         <PlansButton>Assinar plano</PlansButton>
  //       </Link>
  //     </ContainerAction>
  //   </ContainerPlans>
}
