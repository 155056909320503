import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Plans from '../pages/Plans';
import ClientDataForm from '../pages/ClientDataForm';
import PaymentForm from '../pages/PaymentForm';
import FinishPayment from '../pages/FinishPayment';
import Page404 from '../pages/404';
// import DigitalContract from '../pages/DigitalContract';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (true ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{ pathname: '/auth', state: { from: props.location } }}
      />
    ))}
  />
);

const Routes = () => (
  <Switch>
    <Route path="/planos/:cidade" component={Plans} />
    <Route path="/assinar-planos/:cidade/:planId/:insurenceStatus" component={ClientDataForm} />
    <Route path="/pagar-planos/:cidade/:type" component={PaymentForm} />
    <Route path="/sucesso-planos" component={FinishPayment} />
    <Route path="/404" component={Page404} />
    {/* <Route path="/contrato-digital" component={DigitalContract} /> */}
    <Redirect from="/" to="/planos/404" />
  </Switch>
);

export default Routes;
