import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container, HeaderContainer, HeaderLogo, MainContainer,
} from './styles';

import LogoImg from '../../assets/image/logo.png';
import CheckImg from '../../assets/image/checked.png';

export default function FinishPayment() {
  const cidade = sessionStorage.getItem('cidade');
  return (
    <Container>
      <HeaderContainer>
        <HeaderLogo src={LogoImg} alt="Logo" />
      </HeaderContainer>
      <MainContainer>
        <h3>Feito</h3>
        <img src={CheckImg} alt="check icon" />
        <p>
          Em breve você recebera um email
          <br />
          confirmando o cadastro e a compra.
        </p>
        <Link to={`/planos/${cidade}`}>Voltar para planos</Link>
      </MainContainer>
    </Container>
  );
}
