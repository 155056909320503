import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function generateContract(clientData) {
  const dataAutorizacao = format(new Date(), 'dd/MM/yyyy');
  return `
  CONTRATO DE ADESÃO

  O ADERENTE PODERÁ CANCELAR O PRESENTE CONTRATO A QUALQUER HORA, SEM A INCIDÊNCIA DE MULTA, CONFORME Art 3º PARÁGRAFO 3º.

  * Não é plano de saúde. Cartão de desconto assegura apenas os preços e descontos contidos na relação de empresas e serviços conveniados listados no site www.vittamedclub.com.br. Portanto, não garante e não se responsabiliza pelosserviços oferecidos e pelo pagamento das despesas, nem assegura desconto em todos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que o cliente usar ou comprar será pago pelo mesmo ao prestador.


    ADERENTE: ${clientData.Request.Customer.name}, residente e domiciliado a ${clientData.Request.Customer.Address.street},
    ${clientData.Request.Customer.Address.number}
    , no bairro

    ${clientData.Request.Customer.Address.neighborhood}
    , em

    ${clientData.Request.Customer.Address.city}
    -
    ${clientData.Request.Customer.Address.state}

    inscrita no CPF. sob o nº

    ${clientData.Request.Customer.document}
    , portador daCarteira de Identidade R.G. nº

    ${clientData.Request.CustomFields.CP_RG_API}
    , telefone fixo de nº

    ${clientData.Request.Customer.phone}
    , celular nº

    ${clientData.Request.Customer.cellphone}
    ,e-mail

    ${clientData.Request.Customer.email}
    , aderiu ao plano

    ${clientData.plan.title}
    , no valor de

    R$

    ${clientData.plan.price}
    ,no prazo de mensalmente, em Indeterminada parcelas, sendo autorizado pelo aderente na data de

    ${dataAutorizacao}


  Dependentes:
  ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_1_API && (
    `
      ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_1_API}

        ${clientData.Request.CustomFields.CP_CPF_1_API}
        ,

        ${clientData.Request.CustomFields.CP_DATA_NASCIMENTO_1_API}

        `
  )}
  ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_2_API && (
    ` ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_2_API}

      ${clientData.Request.CustomFields.CP_CPF_2_API},

      ${clientData.Request.CustomFields.CP_DATA_NASCIMENTO_2_API}

      `
  )}
  ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_3_API && (
    `
      ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_3_API}

      ${clientData.Request.CustomFields.CP_CPF_3_API},

      ${clientData.Request.CustomFields.CP_DATA_NASCIMENTO_3_API}

    `
  )}
  ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_4_API && (
    `
      ${clientData.Request.CustomFields.CP_NOME_DEPENDENTE_4_API}

      ${clientData.Request.CustomFields.CP_CPF_4_API}
      ,

      ${clientData.Request.CustomFields.CP_DATA_NASCIMENTO_4_API}

    `
  )}


  Art. 1º - Pelo presente Contrato de Adesão e seus anexos,são oferecidos ao ADERENTE, convênios com terceiros, em segmentos de serviçosde Consultas Médicas, nas especialidades disponibilizadas em nosso site, bemcomo Exames em Laboratório de Análises Clinicas, Exames de Imagem, Exames deOftalmologia, Ópticas, Escolas de Línguas, Cursos Profissionalizantes e Lazer,a custos reduzidos disponibilizados pelo CNPJ: 30.370.873/0001-57 aqui denominado Vitta MedClub.

  1º: O ADERENTE declara estar ciente da lista de todas asempresas conveniadas com o Vitta Med Club presentes em nosso site e especificadono caput do Art. 1º. Havendo mudança ou substituição do prestador de serviçoconveniado, o Vitta Med Club informa que a atualização da lista de empresaspoderá ser acessada em seu sítio eletrônico oficial www.vittamedclub.com.br,bem como através do envio de comunicação eletrônica ao ADERENTE que tiverfornecido ao Vitta Med Club o seu endereço eletrônico para correspondência nomomento da assinatura do contrato.

  2º: Terá direito ao acesso às empresas conveniadas oADERENTE no caso do plano Individual, e seus familiares (cônjuge e filhos até aidade de 21 anos completos) no caso do plano familiar, desde que devidamenteinscritos junto ao Vitta Med Club.

  3º: O Vitta Med Club não se responsabiliza pelos serviçosprestados pelas empresas conveniadas, bem como por sua qualidade técnica e pelorecebimento dos valores estabelecidos pelas mesmas.

  4º: Somente o ADERENTE que realizar o pagamento da 1ºmensalidade e que esteja rigorosamente em dia com as suas obrigações financeirasjunto ao Vitta Med Club, terá direito aos serviços e vantagens por eleintermediados.

  5º: Inclui-se como vantagens ao titular ADERENTE rigorosamente adimplente, maior de 16 anos e com menos de 70 anos no ato da adesão, que tenha quitado a primeira parcela, o seguro diária deInternação Hospitalar - Acidente ou Doença - DIH-AD (Até 10 diárias no valor deR$ 300,00, cada.), Reembolso de Despesas com Funeral no valor de R$ 4.000,00, morte de R$5.000,00 (cinco mil reais) no caso de morte natural ouacidental, seguro de Invalidez Permanente Total por Acidente ? IPTA no valor deR$ 5.000,00 (cinco mil reais), conforme condições gerais e especiais entreguesao ADERENTE neste ato e que também se encontra disponível no site HTTP://www.vittamedclub.com.br/seguro_de_vida.pdf,com vigência de 365 dias a partir do pagamento da primeira parcela, podendo o referido prazo ser prorrogado a critério do Vitta Med Club. No plano individual plus o prêmio mensal do seguro é de R$ 9,50 (nove reais e cinquenta centavos). Nos planos Família 2Plus, Família 3 Plus, Família 4 Plus e Família 5 Plus, o prêmio mensal doseguro é de R$ 7,35 (sete reais e trinta e cinco centavos) por cada membro da família. Tais valores já estão inclusos na assinatura de cada plano, de acordo com o plano escolhido.

  6º: O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38, e que a aceitação do seguro estará sujeita a análisede risco, e o registro deste plano na Susep não implica, por parte daautarquia, incentivo ou recomendação a sua comercialização.

  Art. 2º - O ADERENTE obriga-se a pagar ao Vitta Med Club,a partir da assinatura deste, o valor anual de ${clientData.plan.price}, o prazo de Mensal,em Indeterminada parcelas, mediante autorização de débito no cartão de crédito ou boleto bancário informado pelo aderente.

  1º: O ADERENTE pagará ao Vitta Med Club,  a taxa de ativação do ADERENTE no valor de R$50,00 (cinquenta reais), no ato da adesão , que não se confunde com a 1º mensalidade,.

  2º: Ospagamentos mensais e sucessivos tratados no caput somente serão devidos, bemcomo os serviços ora contratados somente serão disponibilizados, após opagamento da 1º mensalidade através da forma de arrecadação elegida pelocliente.

  3º: É deinteira responsabilidade do ADERENTE, manter o Vitta Med Club informado sobrequaisquer alterações no cadastro e na forma de cobrança.

  4º: Oreajuste anual da mensalidade ocorrerá em Janeiro de cada ano de acordo com oIGPM integral da FGV do ano anterior, sendo informado aos ADERENTES com nomínimo 30 (trinta) dias de antecedência de sua efetivação, por meio do sítioeletrônico www.vittamedclub.com.br,bem como pelo envio de comunicação eletrônica àqueles clientes que tiveremfornecido o seu endereço eletrônico no momento da assinatura deste contrato.

  5º: O VittaMed Club não se responsabiliza pelas informações prestadas pelo ADERENTE nomomento da assinatura do contrato, reservando-se no direito de regresso, em caso de fraude.

  Art 3º - Opresente contrato tem validade de prazo de 12 (doze) meses, contados do pagamento da 1º mensalidade conforme Art. 2º, 2º do presente, renovando-se,automaticamente, por prazo indeterminado, caso não haja manifestação expressaem contrário por uma das partes.

  1º: o ADERENTE poderá rescindir o presente contrato sem quaisquer ônus, em qualquer tempo, em qualquer uma das unidades Vitta Med Club, ou pelo e-mail suporte@vittamedclub.com.br .

  2º: Após arenovação automática do presente contrato por prazo indeterminado, o mesmopoderá ser rescindido por qualquer uma das partes, sem multa, mediantecomunicação prévia de 30 (trinta) dias, por escrito, diretamente em qualqueruma das unidades Vitta Med Club.

  3º: A rescisão do presente instrumento só será efetivada,em qualquer hipótese, mediante o pagamento de todas as mensalidades em atraso,sem a incidência de multas.

  4º: Ficaressalvado o direito de cobrança extrajudicial e judicial, pelo Vitta Med Club,da(s) mensalidade(s) não quitada(s) e em atraso pelo ADERENTE, acrescido demulta de 2% e juros moratórios de 1% a.m.

  5º: A suspensão ou cancelamento das cobranças das mensalidades não implica em cancelamento do contrato de adesão ou renúncia do Vitta Med Club ao seu direito de cobrar amensalidade do ADERENTE por outro meio.

  Art. 4º - OADERENTE se declara esclarecido e de acordo com as cláusulas do presentecontrato, bem como está ciente de que o Vitta Med Club não é plano de saúde. Éum Cartão de desconto que assegura apenas os preços e descontos contidos narelação de empresas e serviços conveniados listados no sitewww.vittamedclub.com.br. Portanto, não garante e não se responsabiliza pelosserviços oferecidos e pelo pagamento das despesas, nem assegura desconto emtodos os serviços obrigatoriamente garantidos por plano de saúde. Tudo o que ocliente usar ou comprar será pago pelo mesmo ao prestador.

  Art. 6º - Opresente contrato deverá  ser interpretadode acordo com as regras previstas no Código de Defesa do Consumidor.

  Art. 7º - Aspartes elegem o Foro de Comarca Local, com renúncia expressa de qualquer outro,por mais privilegiado que seja ou venha a ser.

  Por estarem assim justos e contratados, firmam o presente instrumento, .


    Londrina,

    ${format(new Date(), "dd 'de' MMMM yyyy", { locale: ptBR })}


  OBSERVAÇÕES
  O ADERENTE PODE CANCELAR O PRESENTE CONTRATO A QUALQUER HORA, SEM A INCIDÊNCIA DE MULTA, CONFORME Art 3º PARÁGRAFO 3º.
  `;
}
