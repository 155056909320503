import styled from 'styled-components';
import InputMask from 'react-input-mask';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: ${colors.secondary};
  background: #F5F5F5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  height: 20%;

  background: ${colors.primary};
`;

export const HeaderLogo = styled.img`
  width: 20%;
  height: auto;
  resize: none;
  margin-left: 30px;

  @media (max-width: 320px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 600px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 801px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 1025px) {
    width: 60%;
    margin-left: 2%;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-top: 20px;

  @media (max-width: 320px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 801px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SidebarCard = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 320px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 801px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const InputContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 320px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 801px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const ContainerPayment = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 5px;

  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  margin-right: ${({ mr }) => mr || '0px'};
`;

export const TitlePayment = styled.h3`
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: .04em;
  line-height: 16px;
`;

export const SubtitlePayment = styled.p`
  color: #4c5667;
  font-size: 14px;
  line-height: 1.42857143;
`;

export const NextPaymentText = styled.p`
  color: #000;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.42857143;
  &::before {
    content: "*";
    color: red;
  }
`;

export const LineContainer = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  margin-right: ${({ mr }) => mr || '0px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
`;

export const SubtitleInput = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  color: #4c5667;
  font-size: 14px;
  margin-bottom: 8px;

  ${({ required }) => required && `
    &::after {
      content: " *";
      color: red;
    }
  `}
`;

export const InputForm = styled.input`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;
  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  margin-right: ${({ mr }) => mr || '0px'};
  ::-webkit-input-placeholder {
   color: #afafaf;
}

  :-moz-placeholder { /* Firefox 18- */
    color: #afafaf;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #afafaf;
  }

  :-ms-input-placeholder {
    color: #afafaf;
  }

  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const InputMaskForm = styled(InputMask)`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;
  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  margin-right: ${({ mr }) => mr || '0px'};

  ::-webkit-input-placeholder {
   color: #afafaf;
}

  :-moz-placeholder { /* Firefox 18- */
    color: #afafaf;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #afafaf;
  }

  :-ms-input-placeholder {
    color: #afafaf;
  }


  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const SelectForm = styled.select`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;

  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const RowCheck = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const CheckInput = styled.input``;

export const LabelCheckInput = styled.label`
  margin-left: 10px;

  button {
    background: none;
    border: none;
    color: blue;
    font-size: 15px;
  }
`;

export const PaymentButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
  background: ${colors.primary};
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    font-size: 15px;
    margin-right: 5px;
  }
`;

export const ContractContainer = styled.div`
  button {
    margin-left: 20px;
    margin-top: 20px;
    color: #fff;
    background: ${colors.primary};
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
  }
`;
