import { call, put, select } from 'redux-saga/effects';
import { addDays, format } from 'date-fns';
import { toast } from 'react-toastify';
import ClientsAction from '../ducks/clients';
import api from '../../services/api';

function* getBillStatus(paymentId) {
  try {
    console.log(paymentId);
    const { data: responseBill } = yield call(api.post, 'getPaymentBillInfo', {
      Request: {
        internalId: paymentId,
        // internalId: billData.paymentBillInternalId,
      },
    });

    return responseBill;
  } catch (error) {
    console.log(error);
    return yield put(ClientsAction.loadingFalse());
  }
}

export function* createClient({ data }) {
  try {
    const payDate = format(new Date(), 'dd/MM/yyyy');
    const paymentBillStore = yield select((store) => store.clients.paymentBill);
    const navigation = yield select((store) => store.navigation.navigation);

    if (paymentBillStore) {
      console.log(data);
      const updateCardData = {
        Request: {
          paymentBillInternalId: paymentBillStore.internalId,
          customerInternalId: paymentBillStore.customerInternalId,
          Card: data,
        },
      };

      yield call(api.post, 'updatePaymentBillCard', updateCardData);

      yield call(api.post, 'retryTransaction', {
        Request: {
          customerInternalId: paymentBillStore.customerInternalId,
          paymentBillInternalId: paymentBillStore.internalId,
          transactionInternalId: paymentBillStore.transactions[0].internalId,
        },
      });

      const transactionData = yield getBillStatus(paymentBillStore.internalId);

      if (transactionData.paymentBill.transactions[0].status === 'denied') {
        alert('Cartão não autorizado: Negada na Operadora');
        return yield put(ClientsAction.setPaymentBill(transactionData.paymentBill));
      }

      if (transactionData.paymentBill.transactions[0].status !== 'denied') {
        navigation.push('/sucesso-planos');
        yield put(ClientsAction.setPaymentBill(transactionData.paymentBill));
        yield put(ClientsAction.createClientSuccess(transactionData));
        return navigation.push('/sucesso-planos');
      }
    }

    if (!paymentBillStore) {
      const { Request, plan } = data;
      const firstValuePlan = parseFloat(plan.price + 50).toFixed(2);
      const createClientAndBill = {
        Request: {
          operator: 'cielo',
          // planInternalId: '10',
          planInternalId: plan.id,
          typeBill: 'contract',
          paymentType: 'newCard',
          periodicity: 'monthly',
          quantity: 'indeterminated',
          payday: `${payDate}`,
          value: firstValuePlan,
          ...Request,
        },
      };
      const { data: responseCreateUserAndBill } = yield call(api.post, 'createPaymentBillAndCustomer', createClientAndBill);

      if (responseCreateUserAndBill.type === false) {
        if (responseCreateUserAndBill.message.indexOf('IP') != -1) {
          toast.error('IP bloqueado para a cidade solicitada');
          return yield put(ClientsAction.loadingFalse());
        }

        if (responseCreateUserAndBill.message.indexOf('Endereço') != -1) {
          toast.error('Falou alguma informação de endereço, por favor, verifique e tente novamente');
          return yield put(ClientsAction.loadingFalse());
        }
      }

      yield call(api.post, 'updateIndeterminatedContractValue', {
        Request: {
          paymentBillInternalId: responseCreateUserAndBill.paymentBillInternalId,
          value: parseFloat(plan.price).toFixed(2),
        },
      });

      const transactionData = yield getBillStatus(responseCreateUserAndBill.paymentBillInternalId);

      if (transactionData.paymentBill.transactions[0].status === 'denied') {
        alert('Cartão não autorizado: Negada na Operadora');
        return yield put(ClientsAction.setPaymentBill(transactionData.paymentBill));
      }

      if (transactionData.paymentBill.transactions[0].status !== 'denied') {
        alert('Compra realizada, você recebera em breve um email com mais informações');
        yield put(ClientsAction.setPaymentBill(transactionData.paymentBill));
        yield put(ClientsAction.createClientSuccess(responseCreateUserAndBill));
        return navigation.push('/sucesso-planos');
      }
    }
  } catch (error) {
    console.log(error);
    return yield put(ClientsAction.loadingFalse());
  }
}

export function* createClientBoleto({ data }) {
  try {
    const payDate = format(addDays(new Date(), 3), 'dd/MM/yyyy');
    const paymentBillStore = yield select((store) => store.clients.paymentBill);
    const navigation = yield select((store) => store.navigation.navigation);

    const { Request, plan } = data;
    const firstValuePlan = parseFloat(plan.price + 50).toFixed(2);
    const createClientAndBill = {
      Request: {
        // planInternalId: '10',
        planInternalId: plan.id,
        typeBill: 'contract',
        periodicity: 'monthly',
        quantity: 'indeterminated',
        payday: `${payDate}`,
        fineBoleto: '0',
        interestBoleto: '0',
        value: firstValuePlan,
        ...Request,
      },
    };
    const { data: responseCreateUserAndBill } = yield call(api.post, 'createPaymentBillBoletoAndCustomer', createClientAndBill);
    if (responseCreateUserAndBill.type === false) {
      if (responseCreateUserAndBill.message.indexOf('IP') != -1) {
        toast.error('IP bloqueado para a cidade solicitada');
        return yield put(ClientsAction.loadingFalse());
      }

      if (responseCreateUserAndBill.message.indexOf('Endereço') != -1) {
        toast.error('Falou alguma informação de endereço, por favor, verifique e tente novamente');
        return yield put(ClientsAction.loadingFalse());
      }
    }

    yield call(api.post, 'updateIndeterminatedContractValue', {
      Request: {
        paymentBillInternalId: responseCreateUserAndBill.paymentBillInternalId,
        value: parseFloat(plan.price).toFixed(2),
      },
    });

    const transactionData = yield getBillStatus(responseCreateUserAndBill.paymentBillInternalId);

    window.open(transactionData.paymentBill.transactions[0].boleto);
    yield put(ClientsAction.setPaymentBill(transactionData.paymentBill));
    yield put(ClientsAction.createClientBoletoSuccess(transactionData.paymentBill));
    return navigation.push('/sucesso-planos');
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log(error.response);
    }
    return yield put(ClientsAction.loadingFalse());
  }
}
