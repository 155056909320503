import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  setNavigation: ['data'],
  loadingFalse: null,
});

export const NavigationTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  navigation: null,
  loading: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NAVIGATION]: (state, action) => state.merge({
    loading: false,
    navigation: action.data,
  }),
  [Types.LOADING_FALSE]: (state) => state.merge({ loading: false }),
});
