import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  createClientRequest: ['data'],
  createClientSuccess: ['data'],
  createClientBoletoRequest: ['data'],
  createClientBoletoSuccess: ['data'],
  setPaymentBill: ['data'],
  setClientContractData: ['data'],
  loadingFalse: null,
});

export const ClientTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  clientData: null,
  paymentBill: null,
  clientContractData: null,
  loading: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_CLIENT_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.CREATE_CLIENT_SUCCESS]: (state, action) => state.merge({
    loading: false,
    clientData: action.data,
  }),
  [Types.CREATE_CLIENT_BOLETO_REQUEST]: (state) => state.merge({ loading: true }),
  [Types.CREATE_CLIENT_BOLETO_SUCCESS]: (state, action) => state.merge({
    loading: false,
    clientData: action.data,
  }),
  [Types.SET_PAYMENT_BILL]: (state, action) => state.merge({
    loading: false,
    paymentBill: action.data,
  }),
  [Types.SET_CLIENT_CONTRACT_DATA]: (state, action) => state.merge({
    loading: false,
    clientContractData: action.data,
  }),
  [Types.LOADING_FALSE]: (state) => state.merge({ loading: false }),
});
