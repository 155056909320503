import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { intervalToDuration } from 'date-fns';
import ClientActions from '../../store/ducks/clients';
import {
  Container,
  HeaderContainer,
  HeaderLogo,
  MainContainer,
  SidebarCard,
  InputContainer,
  PlansTitle,
  PlansPrice,
  PlansPriceDesc,
  PaymentMethodText,
  PlansDescriptionList,
  PlansDescription,
  PaymentSub,
  LineContainer,
  LabelInput,
  InputRadius,
  SubtitleInput,
  InputForm,
  InputMaskForm,
  FormTitle,
  SelectForm,
  ActionBotton
} from './styles';

import validarIdade from '../../utils/validarIdade';
import validarDocumento from '../../utils/validarDocumento';
import PlansData from '../../utils/plans';
import FiliaisData from '../../filiais';
import EstadosData from '../../utils/estados';
import LogoImg from '../../assets/image/logo.png';
import CardComponent from '../../components/CardComponent';
import { Alarm } from '@material-ui/icons';

export default function ClientDataForm({history}) {
  const dispatch = useDispatch();
  const { planId, insurenceStatus, cidade } = useParams();
  const [planData, setPlanData] = useState(null);
  const [paymentCreditCard, setPaymentCreditCard] = useState('card');
  const [clientData, setClientData] = useState({
    typeClient: 'naturalPerson',
    document: '',
    name: '',
    email: '',
    phone: '',
    cellphone: '',
    CP_RG_API: '',
    CP_DATA_NASCIMENTO_TITULAR_API: '',
    CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API: 'Selecionar',
  });

  const [addressData, setAddressData] = useState({
    street: '',
    zipCode: '',
    number: '',
    neighborhood: '',
    city: '',
    state: 'Selecionar',
    complement: '',
  });

  const [dependentsData, setDependentsData] = useState([
    {
      CP_NOME_DEPENDENTE_1_API: '',
      CP_DATA_NASCIMENTO_1_API: '',
      CP_CPF_1_API: ''
    },
    {
      CP_NOME_DEPENDENTE_2_API: '',
      CP_DATA_NASCIMENTO_2_API: '',
      CP_CPF_2_API: ''
    },
    {
      CP_NOME_DEPENDENTE_3_API: '',
      CP_DATA_NASCIMENTO_3_API: '',
      CP_CPF_3_API: ''
    },
    {
      CP_NOME_DEPENDENTE_4_API: '',
      CP_DATA_NASCIMENTO_4_API: '',
      CP_CPF_4_API: ''
    },
  ]);

  useEffect(() => {
    const filialFilter = FiliaisData.filter(filial => filial.city === cidade)[0]
    const planFilter = filialFilter.data.plans.filter((plan) => plan.planName === planId)[0];
    setPlanData(planFilter);
  }, []);

  async function handleZip() {
    const zip = addressData.zipCode.replace('-', '');
    const { data } = await axios.get(`https://viacep.com.br/ws/${zip}/json/`);

    return setAddressData({
      ...addressData,
      street: data.logradouro,
      neighborhood: data.bairro,
      city: data.localidade,
      state: data.uf,
    });
  }

  const handleDepents = (name, indexDenp) => ({ target }) => {
    const dataMap = dependentsData.map((denp, index) => indexDenp === index ? {
      ...denp,
      [name]: target.value
    }: {...denp})
    return setDependentsData(dataMap)
  }

  const handleClientData = (name) => ({ target }) => setClientData({ ...clientData, [name]: target.value });

  const handleAddressData = (name) => ({ target }) => setAddressData({ ...addressData, [name]: target.value });

  function DocumentVerify() {
    if(clientData.typeClient === 'naturalPerson') {
      const documentCheck = validarDocumento.validarCPF(clientData.document)

      return documentCheck || alert('CPF invalido')
    }

    const documentCheck = validarDocumento.validarCNPJ(clientData.document)

    return documentCheck || alert('CNPJ invalido')
  }

  function AgesVerify() {
    const titularAgeCheck = validarIdade(clientData.CP_DATA_NASCIMENTO_TITULAR_API)
    const dependentesAgeCheck = dependentsData.map((denp, index) => {
      const nameDenp = `CP_NOME_DEPENDENTE_${index + 1}_API`
      if(!denp[nameDenp]) {
        return true
      }

      return validarIdade(denp[`CP_DATA_NASCIMENTO_${index + 1}_API`])
    })

    let msgAlert = 'As datas de nascimento não estão entre 16 e 70 anos:\n'

    if(!titularAgeCheck) {
      msgAlert = msgAlert + '\n Titular'
    }
    if(!dependentesAgeCheck[0]) {
      msgAlert = msgAlert + '\n Dependente 1'
    }
    if(!dependentesAgeCheck[1]) {
      msgAlert = msgAlert + '\n Dependente 2'
    }
    if(!dependentesAgeCheck[2]) {
      msgAlert = msgAlert + '\n Dependente 3'
    }
    if(!dependentesAgeCheck[3]) {
      msgAlert = msgAlert + '\n Dependente 4'
    }

    if(
      !titularAgeCheck ||
      !dependentesAgeCheck[0] ||
      !dependentesAgeCheck[1] ||
      !dependentesAgeCheck[2] ||
      !dependentesAgeCheck[3]
    ) {
      return {
        status: false,
        msgAlert
      }
    }

    return {
      status: true,
      msgAlert: null
    }

  }

  function handleCreateClient() {
    console.log(clientData)
    console.log(addressData)
    if(
      !clientData.name ||
      !clientData.email ||
      !clientData.phone ||
      !clientData.cellphone||
      !clientData.CP_RG_API ||
      !clientData.CP_DATA_NASCIMENTO_TITULAR_API ||
      clientData.CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API === 'Selecionar' ||
      !addressData.street ||
      !addressData.zipCode ||
      !addressData.neighborhood ||
      !addressData.city ||
      addressData.state === 'Selecionar' ) {
        return alert('Todos os campos com * são obrigatorios, verifique e tente novamente')
      }

      const documentCheckResult = clientData.typeClient === 'naturalPerson' ?
      validarDocumento.validarCPF(clientData.document) :
      validarDocumento.validarCNPJ(clientData.document)

      if(!documentCheckResult) return alert(`${clientData.typeClient === 'naturalPerson' ? 'CPF' : 'CNPJ'} invalido`)

      const ageResult = AgesVerify()

      if(!ageResult.status && ageResult.msgAlert) {
        return alert(ageResult.msgAlert)
      }

    const {
      typeClient,
      CP_RG_API,
      CP_DATA_NASCIMENTO_TITULAR_API,
      CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API,
      ...rest
    } = clientData;

    const finalData = {
      Request: {
        Customer: {
          ...rest,
          Address: addressData,
        },
        CustomFields: {
          CP_RG_API,
          CP_DATA_NASCIMENTO_TITULAR_API,
          CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API,

          CP_DATA_NASCIMENTO_1_API: dependentsData[0].CP_DATA_NASCIMENTO_1_API,
          CP_NOME_DEPENDENTE_1_API: dependentsData[0].CP_NOME_DEPENDENTE_1_API,
          CP_CPF_1_API: dependentsData[0].CP_CPF_1_API,

          CP_DATA_NASCIMENTO_2_API: dependentsData[1].CP_DATA_NASCIMENTO_2_API,
          CP_NOME_DEPENDENTE_2_API: dependentsData[1].CP_NOME_DEPENDENTE_2_API,
          CP_CPF_2_API: dependentsData[1].CP_CPF_2_API,

          CP_DATA_NASCIMENTO_3_API: dependentsData[2].CP_DATA_NASCIMENTO_3_API,
          CP_NOME_DEPENDENTE_3_API: dependentsData[2].CP_NOME_DEPENDENTE_3_API,
          CP_CPF_3_API: dependentsData[2].CP_CPF_3_API,

          CP_DATA_NASCIMENTO_4_API: dependentsData[3].CP_DATA_NASCIMENTO_4_API,
          CP_NOME_DEPENDENTE_4_API: dependentsData[3].CP_NOME_DEPENDENTE_4_API,
          CP_CPF_4_API: dependentsData[3].CP_CPF_4_API,
        },
      },
      plan: planData[insurenceStatus]
    };

    if(paymentCreditCard === "card") {
      return history.push(`/pagar-planos/${cidade}/cartao`, {data: finalData})
    }
    return history.push(`/pagar-planos/${cidade}/boleto`, {data: finalData})
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderLogo src={LogoImg} alt="Logo" />
      </HeaderContainer>
      {planData ? (
        <MainContainer>
          <SidebarCard>
            <PlansTitle>{planData[insurenceStatus].title}</PlansTitle>
            <PlansPrice>
              R$
              {' '}
              {planData[insurenceStatus].price.toFixed(2)}
              / Mês
            </PlansPrice>
            <PlansPriceDesc>{planData[insurenceStatus].priceDesc}</PlansPriceDesc>
            <PaymentMethodText>{paymentCreditCard === 'card' ? 'no Cartão de Crédito' : 'no Boleto'}</PaymentMethodText>
            <PlansDescriptionList>
              <PlansDescriptionList>
                {planData[insurenceStatus].description.map((desc, index) => (
                  <PlansDescription key={index}>{desc}</PlansDescription>
                ))}
              </PlansDescriptionList>
            </PlansDescriptionList>
            <PaymentSub>Plano selecionado para compra</PaymentSub>
          </SidebarCard>
          <InputContainer>
            <CardComponent title="TIPO DE PAGAMENTO">
              <LineContainer justifyContent="flex-start">
                <LineContainer width="auto" justifyContent="flex-start">
                  <InputRadius
                    type="radio"
                    id="card"
                    name="paymentmethod"
                    checked={paymentCreditCard === 'card'}
                    onChange={({ target }) => setPaymentCreditCard(target.value)}
                    value="card"
                  />
                  <LabelInput for="card">Cartão</LabelInput>
                </LineContainer>
                <LineContainer width="auto" justifyContent="flex-start">
                  <InputRadius
                    type="radio"
                    id="banksplip"
                    name="paymentmethod"
                    checked={paymentCreditCard === 'banksplip'}
                    onChange={({ target }) => setPaymentCreditCard(target.value)}
                    value="banksplip"
                  />
                  <LabelInput for="banksplip">Boleto Bancário</LabelInput>
                </LineContainer>
              </LineContainer>
            </CardComponent>

            <CardComponent title="INFORME SEUS DADOS">
              <LineContainer justifyContent="flex-start">
                <SubtitleInput required>Tipo de cadastro</SubtitleInput>
              </LineContainer>
              <LineContainer justifyContent="flex-start">
                <LineContainer width="auto" justifyContent="flex-start">
                  <InputRadius
                    type="radio"
                    id="naturalPerson"
                    name="typeClient"
                    checked={clientData.typeClient === 'naturalPerson'}
                    onChange={handleClientData('typeClient')}
                    value="naturalPerson"
                  />
                  <LabelInput for="naturalPerson">Pessoa fisica</LabelInput>
                </LineContainer>
                <LineContainer width="auto" justifyContent="flex-start">
                  <InputRadius
                    type="radio"
                    id="companyName"
                    name="typeClient"
                    checked={clientData.typeClient === 'companyName'}
                    onChange={handleClientData('typeClient')}
                    value="companyName"
                  />
                  <LabelInput for="companyName">Pessoa jurídica</LabelInput>
                </LineContainer>
              </LineContainer>
              {clientData.typeClient === 'naturalPerson' ? (
                <LineContainer justifyContent="flex-start" mt="30px">
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>CPF</SubtitleInput>
                    <InputMaskForm
                      mask="999.999.999-99"
                      type="text"
                      width="70%"
                      value={clientData.document}
                      onBlur={() =>DocumentVerify()}
                      onChange={handleClientData('document')}
                    />
                  </LineContainer>
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Nome</SubtitleInput>
                    <InputForm
                      type="text"
                      width="70%"
                      value={clientData.name}
                      onChange={handleClientData('name')}
                    />
                  </LineContainer>
                </LineContainer>
              ) : (
                <LineContainer justifyContent="flex-start" mt="30px">
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>CNPJ</SubtitleInput>
                    <InputMaskForm
                      mask="99.999.999/9999-99"
                      width="70%"
                      value={clientData.document}
                      onBlur={() => DocumentVerify()}
                      onChange={handleClientData('document')}
                    />
                  </LineContainer>
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Razão social</SubtitleInput>
                    <InputForm
                      type="text"
                      width="70%"
                      value={clientData.name}
                      onChange={handleClientData('name')}
                    />
                  </LineContainer>
                </LineContainer>
              )}

              <LineContainer justifyContent="flex-start" mt="20px">
              <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>RG</SubtitleInput>
                    <InputForm
                      type="text"
                      width="70%"
                      value={clientData.CP_RG_API}
                      onChange={handleClientData('CP_RG_API')}
                    />
                  </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Email</SubtitleInput>
                  <InputForm
                    type="text"
                    width="70%"
                    value={clientData.email}
                    onChange={handleClientData('email')}
                  />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Telefone</SubtitleInput>
                  <InputMaskForm
                    mask="(99) 9999-9999"
                    width="70%"
                    value={clientData.phone}
                    onChange={handleClientData('phone')}
                  />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Celular</SubtitleInput>
                  <InputMaskForm
                    mask="(99) 99999-9999"
                    width="70%"
                    value={clientData.cellphone}
                    onChange={handleClientData('cellphone')}
                  />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="30px">
                <FormTitle>Endereço</FormTitle>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="10px">
                <LineContainer
                  width="20%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>CEP</SubtitleInput>
                  <InputMaskForm
                    mask="99999-999"
                    width="85%"
                    onBlur={() => handleZip()}
                    value={addressData.zipCode}
                    onChange={handleAddressData('zipCode')}
                  />
                </LineContainer>
                <LineContainer
                  width="60%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Logradouro</SubtitleInput>
                  <InputForm
                    type="text"
                    width="85%"
                    value={addressData.street}
                    onChange={handleAddressData('street')}
                  />
                </LineContainer>
                <LineContainer
                  width="20%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Número </SubtitleInput>
                  <InputForm
                    type="text"
                    width="85%"
                    value={addressData.number}
                    onChange={handleAddressData('number')}
                  />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="33%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Complemento</SubtitleInput>
                  <InputForm
                    type="text"
                    width="85%"
                    value={addressData.complement}
                    onChange={handleAddressData('complement')}
                  />
                </LineContainer>
                <LineContainer
                  width="33%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Bairro</SubtitleInput>
                  <InputForm
                    type="text"
                    width="85%"
                    value={addressData.neighborhood}
                    onChange={handleAddressData('neighborhood')}
                  />
                </LineContainer>
                <LineContainer
                  width="33%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Cidade</SubtitleInput>
                  <InputForm
                    type="text"
                    width="85%"
                    value={addressData.city}
                    onChange={handleAddressData('city')}
                  />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="33%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Estado</SubtitleInput>
                  <SelectForm
                    value={addressData.state}
                    onChange={handleAddressData('state')}
                    width="90%"
                  >
                    <option value="Selecionar">Selecionar</option>
                    {EstadosData.map((estado) => (
                      <option value={estado.sigla} key={estado.sigla}>{estado.nome}</option>
                    ))}
                  </SelectForm>
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="30px">
                <FormTitle>Informações adicionais</FormTitle>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="10px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Data Nascimento Titular</SubtitleInput>
                  <InputForm
                    type="date"
                    width="90%"
                    onBlur={() => validarIdade(clientData.CP_DATA_NASCIMENTO_TITULAR_API) || alert('Data nascimento invalido, idade permitida entre 16 e 70 anos')}
                    value={clientData.CP_DATA_NASCIMENTO_TITULAR_API}
                    onChange={handleClientData('CP_DATA_NASCIMENTO_TITULAR_API')}
                  />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput required>Sou Pessoa Politicamente Exposta?</SubtitleInput>
                  <SelectForm
                    width="90%"
                    value={clientData.CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API}
                    onChange={handleClientData('CP_SOU_PESSOA_POLITICAMENTE_EXPOSTA_API')}
                  >
                    <option value="Selecionar">Selecionar</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </SelectForm>
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="100%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Nome Dependente 1 (não preencher se plano for individual)</SubtitleInput>
                  <InputForm type="text" width="100%"
                  value={dependentsData[0].CP_NOME_DEPENDENTE_1_API}
                  onChange={handleDepents('CP_NOME_DEPENDENTE_1_API', 0)}/>
                </LineContainer>
              </LineContainer>
              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="100%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Nome Dependente 2 (não preencher se plano for individual)</SubtitleInput>
                  <InputForm type="text" width="100%" value={dependentsData[1].CP_NOME_DEPENDENTE_2_API}
                  onChange={handleDepents('CP_NOME_DEPENDENTE_2_API', 1)}/>
                </LineContainer>
              </LineContainer>
              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="100%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Nome Dependente 3 (não preencher se plano for individual)</SubtitleInput>
                  <InputForm type="text" width="100%" value={dependentsData[2].CP_NOME_DEPENDENTE_3_API}
                  onChange={handleDepents('CP_NOME_DEPENDENTE_3_API', 2)}/>
                </LineContainer>
              </LineContainer>
              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="100%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Nome Dependente 4 (não preencher se plano for individual)</SubtitleInput>
                  <InputForm type="text" width="100%" value={dependentsData[3].CP_NOME_DEPENDENTE_4_API}
                  onChange={handleDepents('CP_NOME_DEPENDENTE_4_API', 3)}/>
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Cpf 1</SubtitleInput>
                  <InputMaskForm mask="999.999.999-99" width="90%" value={dependentsData[0].CP_CPF_1_API}
                  onChange={handleDepents('CP_CPF_1_API', 0)} />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Data Nascimento 1</SubtitleInput>
                  <InputForm type="date" width="90%" value={dependentsData[0].CP_DATA_NASCIMENTO_1_API}
                  onBlur={() => validarIdade(dependentsData[0].CP_DATA_NASCIMENTO_1_API) || alert('Data nascimento invalido, idade permitida entre 16 e 70 anos')}
                  onChange={handleDepents('CP_DATA_NASCIMENTO_1_API', 0)} />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Cpf 2</SubtitleInput>
                  <InputMaskForm mask="999.999.999-99" width="90%" value={dependentsData[1].CP_CPF_2_API}
                  onChange={handleDepents('CP_CPF_2_API', 1)} />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Data Nascimento 2</SubtitleInput>
                  <InputForm type="date" width="90%" value={dependentsData[1].CP_DATA_NASCIMENTO_2_API}
                  onBlur={() => validarIdade(dependentsData[1].CP_DATA_NASCIMENTO_2_API) || alert('Data nascimento invalido, idade permitida entre 16 e 70 anos')}
                  onChange={handleDepents('CP_DATA_NASCIMENTO_2_API', 1)} />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Cpf 3</SubtitleInput>
                  <InputMaskForm mask="999.999.999-99" width="90%" value={dependentsData[2].CP_CPF_3_API}
                  onChange={handleDepents('CP_CPF_3_API', 2)} />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Data Nascimento 3</SubtitleInput>
                  <InputForm type="date" width="90%" value={dependentsData[2].CP_DATA_NASCIMENTO_3_API}
                  onBlur={() => validarIdade(dependentsData[2].CP_DATA_NASCIMENTO_3_API) || alert('Data nascimento invalido, idade permitida entre 16 e 70 anos')}
                  onChange={handleDepents('CP_DATA_NASCIMENTO_3_API', 2)} />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px" mb="30px">
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Cpf 4</SubtitleInput>
                  <InputMaskForm mask="999.999.999-99" width="90%" value={dependentsData[3].CP_CPF_4_API}
                  onChange={handleDepents('CP_CPF_4_API', 3)} />
                </LineContainer>
                <LineContainer
                  width="50%"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <SubtitleInput>Data Nascimento 4</SubtitleInput>
                  <InputForm type="date" width="90%" value={dependentsData[3].CP_DATA_NASCIMENTO_4_API}
                  onBlur={() => validarIdade(dependentsData[3].CP_DATA_NASCIMENTO_4_API) || alert('Data nascimento invalido, idade permitida entre 16 e 70 anos')}
                  onChange={handleDepents('CP_DATA_NASCIMENTO_4_API', 3)} />
                </LineContainer>
              </LineContainer>

              <LineContainer justifyContent="flex-start" mt="20px" mb="30px">
                <LineContainer
                  width="100%"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="flex-end"
                >
                  <ActionBotton onClick={() => handleCreateClient()}>Avançar ></ActionBotton>
                </LineContainer>
              </LineContainer>

            </CardComponent>
          </InputContainer>
        </MainContainer>
      ) : (null)}
    </Container>
  );
}
