import { all, takeLatest } from 'redux-saga/effects';

import { ClientTypes } from '../ducks/clients';
import { createClient, createClientBoleto } from './clients';

export default function* rootSaga() {
  yield all([
    takeLatest(ClientTypes.CREATE_CLIENT_REQUEST, createClient),
    takeLatest(ClientTypes.CREATE_CLIENT_BOLETO_REQUEST, createClientBoleto),
  ]);
}
