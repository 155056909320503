import axios from 'axios';
import FilialData from '../filiais';

const api = axios.create({
  // baseURL: "http://localhost:3333",
  baseURL: 'https://app.galaxpay.com.br/webservice',
});

api.interceptors.request.use(async (config) => {
  const cidade = sessionStorage.getItem('cidade');
  const filialData = FilialData.filter((filial) => filial.city === cidade)[0];

  console.log(cidade);
  console.log(filialData);

  config.data = {
    ...config.data,
    Auth: {
      galaxId: filialData.data.galaxId,
      galaxHash: filialData.data.galaxHash,
      // galaxId: '5473',
      // galaxHash: '83Mw5u8988Qj6fZqS4Z8K7LzOo1j28S706R0BeFe',
    },
  };
  return config;
});

export default api;
