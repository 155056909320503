import creditCardType from 'credit-card-type';
import bandeirasCartao from './bandeirasCartao';

export default function checkCreditCardType(cardNumber) {
  const cardFormated = cardNumber.replace(/( )+/g, '');
  console.log(cardFormated);
  console.log(cardFormated.length);
  if (cardFormated.length != 16
		|| cardFormated == '0000000000000000'
		|| cardFormated == '1111111111111111'
		|| cardFormated == '2222222222222222'
		|| cardFormated == '3333333333333333'
		|| cardFormated == '4444444444444444'
		|| cardFormated == '5555555555555555'
		|| cardFormated == '6666666666666666'
		|| cardFormated == '7777777777777777'
		|| cardFormated == '8888888888888888'
		|| cardFormated == '9999999999999999') {
    return {
      status: false,
      type: null,
    };
  }
  const checkCard = creditCardType(cardFormated);

  const mapBandeira = bandeirasCartao.filter((bandeira) => bandeira.type === checkCard[0].type);

  if (mapBandeira.length === 0) {
    return {
      status: true,
      type: null,
    };
  }

  return {
    status: true,
    type: checkCard[0].type,
  };
}
