import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { format, addMonths, addDays } from 'date-fns';
import { Check } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import ClientsAction from '../../store/ducks/clients';
import NavigationAction from '../../store/ducks/navigation';
import {
  Container,
  HeaderContainer,
  HeaderLogo,
  SidebarCard,
  MainContainer,
  InputContainer,
  ContainerPayment,
  LineContainer,
  SubtitlePayment,
  TitlePayment,
  NextPaymentText,
  InputForm,
  InputMaskForm,
  SubtitleInput,
  SelectForm,
  RowCheck,
  CheckInput,
  LabelCheckInput,
  PaymentButton,
  ContractContainer,
} from './styles';

import validarCreditCard from '../../utils/validarCartao';
import BandeirasCartao from '../../utils/bandeirasCartao';
import LogoImg from '../../assets/image/logo.png';
import DigitalContract from '../DigitalContract';
import generateContract from '../../utils/generateContract';

export default function PaymentForm({ history, match }) {
  const { type, cidade } = useParams();
  const dispatch = useDispatch();
  const paymentBillStore = useSelector((store) => store.clients.paymentBill);
  const loadingStore = useSelector((store) => store.clients.loading);
  const [termDialogStatus, setTermDialogStatus] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [checkOutData, setCheckOutData] = useState(null);
  const [generateBoletoStatus, setGenerateBoletoStatus] = useState(false);
  const [cardData, setCardData] = useState({
    number: '',
    holder: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    brand: 'Selecionar',
  });

  useEffect(() => {
    // console.log('clientData', history);
    sessionStorage.setItem('cidade', cidade);
    const clientData = history.location.state;
    if (!clientData) return history.push('/planos');

    dispatch(NavigationAction.setNavigation(history));

    setCheckOutData({
      ...checkOutData,
      Request: clientData.data.Request,
      plan: clientData.data.plan,
    });
  }, []);

  const handleCardData = (name) => ({ target }) => setCardData({ ...cardData, [name]: target.value });

  function handleCheckCreditCard() {
    const checkCard = validarCreditCard(cardData.number);

    if (!checkCard.status) {
      return alert('Cartão invalido:\nVerifique se o cartão informado esta valido ou\nse faz parte das bandeiras autorizadas');
    }

    return setCardData({
      ...cardData,
      brand: checkCard.type,
    });
  }

  function handleCreateUserAndBill() {
    if (
      !cardData.number
      || !cardData.holder
      || !cardData.expiryMonth
      || !cardData.expiryYear
      || !cardData.cvv
      || !cardData.brand === 'Selecionar') {
      return alert('Todos os campos marcados com * são obrigatorios:\nVerifique e tente novamente');
    }

    if (!acceptTerm) return alert('Marque que você aceita o contrato digital');

    if (paymentBillStore) {
      return dispatch(ClientsAction.createClientRequest({ ...cardData }));
    }

    const bodyNewClient = {
      ...checkOutData,
      Request: {
        ...checkOutData.Request,
        CustomFields: {
          ...checkOutData.Request.CustomFields,
          CP_CONTRATO_DIGITAL_API: generateContract(checkOutData),
        },
        Card: cardData,
      },
    };

    return dispatch(ClientsAction.createClientRequest(bodyNewClient));
  }

  function handleCreateUserAndBoleto() {
    if (!acceptTerm) return alert('Marque que você aceita o contrato digital');

    if (!generateBoletoStatus) {
      return alert('Selecione o boleto para podermos gerar');
    }

    const bodyNewClient = {
      ...checkOutData,
      Request: {
        ...checkOutData.Request,
        CustomFields: {
          ...checkOutData.Request.CustomFields,
          CP_CONTRATO_DIGITAL_API: generateContract(checkOutData),
        },
      },
    };

    return dispatch(ClientsAction.createClientBoletoRequest(bodyNewClient));
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderLogo src={LogoImg} alt="Logo" />
      </HeaderContainer>
      {checkOutData ? (
        <MainContainer>
          <SidebarCard>
            <ContainerPayment>
              <LineContainer>
                <TitlePayment>DADOS DOs PAGAMENTO</TitlePayment>
              </LineContainer>
              <LineContainer mt="20px">
                <SubtitlePayment>Cliente</SubtitlePayment>
                <SubtitlePayment>{checkOutData.Request.Customer.name}</SubtitlePayment>
              </LineContainer>
              <LineContainer mt="2px">
                <SubtitlePayment>Plano</SubtitlePayment>
                <SubtitlePayment>{checkOutData.plan.title}</SubtitlePayment>
              </LineContainer>
              <LineContainer mt="2px">
                <SubtitlePayment>Periodicidade</SubtitlePayment>
                <SubtitlePayment>Mensal</SubtitlePayment>
              </LineContainer>
            </ContainerPayment>

            <ContainerPayment mt="10px">
              <LineContainer>
                <TitlePayment>TRANSAÇÕES</TitlePayment>
              </LineContainer>
              <LineContainer mt="20px">
                <SubtitlePayment>Vencimento</SubtitlePayment>
                <SubtitlePayment>{format(new Date(), 'dd/MM/yyyy')}</SubtitlePayment>
              </LineContainer>
              <LineContainer mt="25px">
                <SubtitlePayment>Valor do plano</SubtitlePayment>
                <SubtitlePayment>
                  R$
                  {' '}
                  {parseFloat(checkOutData.plan.price).toFixed(2)}
                </SubtitlePayment>
              </LineContainer>
              {checkOutData.plan.title.indexOf('com seguro') > -1 && (
              <LineContainer mt="6px">
                <SubtitlePayment>Valor do seguro por pessoa</SubtitlePayment>
                <SubtitlePayment>
                  R$
                  {' '}
                  {checkOutData.plan.multiplierDependent === 1 ? '9.50' : '7.35'}
                </SubtitlePayment>
              </LineContainer>

              )}
              {checkOutData.plan.title.indexOf('com seguro') > -1 && (
              <LineContainer mb="6px" alignItems="flex-start">
                <SubtitlePayment>
                  Valor total do seguro
                  {' '}
                  <br />
                  <span style={{
                    fontSize: '0.9em',
                    fontWeight: 'bold',
                  }}
                  >
                    (já incluso no valor do plano)

                  </span>
                </SubtitlePayment>
                <SubtitlePayment>
                  {checkOutData.plan.multiplierDependent === 1 ? 'R$ 9.50' : `R$ ${parseFloat(checkOutData.plan.multiplierDependent * 7.35).toFixed(2)}` }
                </SubtitlePayment>
              </LineContainer>

              )}
              <LineContainer mt="2px">
                <SubtitlePayment>Taxa de Adesão (uma vez)</SubtitlePayment>
                <SubtitlePayment>
                  R$ 50.00
                </SubtitlePayment>
              </LineContainer>
              <LineContainer mt="10px">
                <SubtitlePayment style={{ fontWeight: 700 }}>Total</SubtitlePayment>
                <SubtitlePayment style={{ fontWeight: 700 }}>
                  R$
                  {' '}
                  {parseFloat(checkOutData.plan.price + 50).toFixed(2)}
                </SubtitlePayment>
              </LineContainer>
              <LineContainer mt="2px">
                <SubtitlePayment>Status</SubtitlePayment>
                <SubtitlePayment>Aguardando pagamento</SubtitlePayment>
              </LineContainer>
              <LineContainer mt="15px">
                <NextPaymentText>
                  A próxima transação ficará disponível para pagamento a partir do dia
                  {' '}
                  {format(addMonths(new Date(), 1), 'dd/MM/yyyy')}
                </NextPaymentText>
              </LineContainer>
            </ContainerPayment>
          </SidebarCard>
          <InputContainer>
            <ContainerPayment ml="15px">
              <LineContainer>
                <TitlePayment>DADOS DA EMPRESA</TitlePayment>
              </LineContainer>
              <LineContainer mt="20px">
                <SubtitlePayment>Razão Socia:</SubtitlePayment>
                <SubtitlePayment>Vitta Med Serviços Administrativos Ltda</SubtitlePayment>
              </LineContainer>
              <LineContainer mt="2px">
                <SubtitlePayment>CNPJ:</SubtitlePayment>
                <SubtitlePayment>26.237.697/0001-02</SubtitlePayment>
              </LineContainer>
            </ContainerPayment>
            {type === 'cartao' ? (
              <ContainerPayment mt="20px" ml="15px">
                <LineContainer>
                  <TitlePayment>Pagar com cartão</TitlePayment>
                </LineContainer>

                <LineContainer justifyContent="flex-start" mt="20px">
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Número do cartão</SubtitleInput>
                    <InputMaskForm
                      mask="9999 9999 9999 9999"
                      width="90%"
                      placeholder="EXEMPLO: 3333 3333 3333 3333"
                      onBlur={() => handleCheckCreditCard()}
                      value={cardData.number}
                      onChange={handleCardData('number')}
                    />
                  </LineContainer>
                  <LineContainer
                    width="50%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Bandeira</SubtitleInput>
                    <SelectForm
                      width="90%"
                      value={cardData.brand}
                      onChange={handleCardData('brand')}
                    >
                      <option value="Selecionar">Selecionar</option>
                      {BandeirasCartao.map((bandeira) => (
                        <option value={bandeira.internalName} key={bandeira.internalName}>{bandeira.name}</option>
                      ))}
                    </SelectForm>
                  </LineContainer>
                </LineContainer>

                <LineContainer justifyContent="flex-start" mt="20px">
                  <LineContainer
                    width="100%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Nome escrito no cartão</SubtitleInput>
                    <InputForm
                      type="text"
                      width="95%"
                      placeholder="EXEMPLO: MARIA LUIZA C SOUZA"
                      value={cardData.holder}
                      onChange={handleCardData('holder')}
                    />
                  </LineContainer>
                </LineContainer>
                <LineContainer justifyContent="flex-start" mt="20px">
                  <LineContainer
                    width="100%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Validade</SubtitleInput>
                  </LineContainer>
                </LineContainer>
                <LineContainer
                  width="100%"
                  flexDirection="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <InputMaskForm
                    mask="99"
                    placeholder="MM"
                    width="20%"
                    mr="15px"
                    value={cardData.expiryMonth}
                    onChange={handleCardData('expiryMonth')}
                  />
                  <InputMaskForm
                    placeholder="AAAA"
                    mask="9999"
                    max={4}
                    width="25%"
                    value={cardData.expiryYear}
                    onChange={handleCardData('expiryYear')}
                  />
                </LineContainer>
                <LineContainer justifyContent="flex-start" mt="20px">
                  <LineContainer
                    width="100%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <SubtitleInput required>Código de Segurança</SubtitleInput>
                    <InputForm
                      placeholder="EXEMPLO: 123"
                      width="95%"
                      value={cardData.cvv}
                      onChange={handleCardData('cvv')}
                    />
                  </LineContainer>
                </LineContainer>

                <LineContainer justifyContent="flex-start" mt="30px">
                  <LineContainer
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <CheckInput type="checkbox" checked={acceptTerm} onChange={() => setAcceptTerm(!acceptTerm)} />
                    <LabelCheckInput>
                      Eu aceito o
                      {' '}
                      <button onClick={() => setTermDialogStatus(true)}>
                        contrato digital
                      </button>
                      {' '}
                    </LabelCheckInput>
                  </LineContainer>
                </LineContainer>
                <LineContainer justifyContent="flex-start" mt="10px">
                  <LineContainer
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {loadingStore ? (
                      <PaymentButton disabled onClick={() => handleCreateUserAndBill()}>
                        Carregando...
                      </PaymentButton>

                    ) : (

                      <PaymentButton onClick={() => handleCreateUserAndBill()}>
                        <Check />
                        {' '}
                        Pagar e autorizar cobranças neste cartão até que eu peça cancelamento
                      </PaymentButton>
                    )}
                  </LineContainer>
                </LineContainer>
              </ContainerPayment>
            ) : (
              <ContainerPayment mt="20px" ml="15px">
                <LineContainer>
                  <TitlePayment>Gerar boleto</TitlePayment>
                </LineContainer>

                <LineContainer justifyContent="flex-start" mt="30px">
                  <LineContainer
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <CheckInput
                      type="checkbox"
                      checked={generateBoletoStatus}
                      onChange={() => setGenerateBoletoStatus(!generateBoletoStatus)}
                    />
                    <LabelCheckInput>
                      Gerar boleto para transação de vencimento dia
                      {' '}
                      {format(addDays(new Date(), 3), 'dd/MM/yyyy')}
                    </LabelCheckInput>
                  </LineContainer>
                </LineContainer>
                <LineContainer
                  width="100%"
                  mt="40px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <CheckInput type="checkbox" checked={acceptTerm} onChange={() => setAcceptTerm(!acceptTerm)} />
                  <LabelCheckInput>
                    Eu aceito o
                    {' '}
                    <button onClick={() => setTermDialogStatus(true)}>
                      contrato digital
                    </button>
                    {' '}
                  </LabelCheckInput>
                </LineContainer>
                <LineContainer justifyContent="flex-start" mt="10px">
                  <LineContainer
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {loadingStore ? (
                      <PaymentButton disabled onClick={() => handleCreateUserAndBill()}>
                        Carregando...
                      </PaymentButton>

                    ) : (

                      <PaymentButton onClick={() => handleCreateUserAndBoleto()}>
                        <Check />
                        {' '}
                        Gerar boleto
                      </PaymentButton>
                    )}
                  </LineContainer>
                </LineContainer>
              </ContainerPayment>
            )}

          </InputContainer>
        </MainContainer>

      ) : null}
      <Dialog fullScreen open={termDialogStatus} onClose={() => setTermDialogStatus(false)}>
        <ContractContainer>
          <button onClick={() => setTermDialogStatus(false)}>Fechar</button>
          <DigitalContract clientData={checkOutData} />
        </ContractContainer>
      </Dialog>
    </Container>
  );
}
