export default {
  city: 'Hortolandia',
  name: 'Atend já',
  address: 'Rua José Camilo de Camargo, 141 A  Centro',
  galaxId: '8939',
  galaxHash: '1nAmHcPpLyOdI6HlYlSe7769J39z7g5zEgAjAwW5',
  plans: [
    {
      planName: 'plano-individual',
      withoutInsurance: {
        id: 28,
        title: 'PLANO INDIVIDUAL (sem seguro)',
        price: 29.90,
        multiplierDependent: null,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela).',
        ],
      },
      insurance: {
        id: 27,
        title: 'PLANO INDIVIDUAL PLUS (com seguro)',
        price: 39.90,
        multiplierDependent: 1,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Diárias de Internação Hospitalar por Acidente ou Doença (até10 diárias de R$ 300,00 cada). O capital segurado será pago de uma única vez eserá calculado após a regulação do sinistro.',
          'Morte (R$ 5.000,00)',
          'Reembolso de Despesas com Funeral Familiar (R$ 4.000,00).',
          '(Ver regras e prazos de franquia no contrato digital nopasso 3, parágrafo 6). O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38.',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela). Valormensal do seguro: R$ 9,50 (já incluso no plano).',
        ],
      },
    },
    {
      planName: 'plano-familia-2',
      withoutInsurance: {
        id: 26,
        title: 'PLANO FAMÍLIA 2 (sem seguro)',
        price: 44.90,
        multiplierDependent: null,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 2 membros',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 2 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela).',
        ],
      },
      insurance: {
        id: 25,
        title: 'PLANO FAMÍLIA 2 PLUS (com seguro)',
        price: 49.90,
        multiplierDependent: 2,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 2 membros',
          'Diárias de Internação Hospitalar por Acidente ou Doença (até10 diárias de R$ 300,00 cada). O capital segurado será pago de uma única vez eserá calculado após a regulação do sinistro.',
          'Morte (R$ 5.000,00)',
          'Invalidez Permanente e Total por Acidente (R$ 5.000,00)',
          'Reembolso de Despesas com Funeral Titular (R$ 4.000,00).',
          '(Ver regras e prazos de franquia no contrato digital nopasso 3, parágrafo 6). O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38.',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 2 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela). Valormensal do seguro: R$ 7,35 / por vida. ',
        ],
      },
    },
    {
      planName: 'plano-familia-3',
      withoutInsurance: {
        id: 24,
        title: 'PLANO FAMÍLIA 3 (sem seguro)',
        price: 54.90,
        multiplierDependent: null,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 3 membros',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 3 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeiraparcela).',
        ],
      },
      insurance: {
        id: 23,
        title: 'PLANO FAMÍLIA 3 PLUS (com seguro)',
        price: 59.90,
        multiplierDependent: 3,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 3 membros',
          'Diárias de Internação Hospitalar por Acidente ou Doença (até10 diárias de R$ 300,00 cada). O capital segurado será pago de uma única vez eserá calculado após a regulação do sinistro.',
          'Morte (R$ 5.000,00),',
          'Invalidez Permanente e Total por Acidente (R$ 5.000,00)',
          'Reembolso de Despesas com Funeral Titular (R$ 4.000,00).',
          '(Ver regras e prazos de franquia no contrato digital nopasso 3, parágrafo 6). O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38.',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 3 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela). Valormensal do seguro: R$ 7,35 / por vida. ',
        ],
      },
    },
    {
      planName: 'plano-familia-4',
      withoutInsurance: {
        id: 22,
        title: 'PLANO FAMÍLIA 4 (sem seguro)',
        price: 64.90,
        multiplierDependent: null,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 4 membros',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 4 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeiraparcela). ',
        ],
      },
      insurance: {
        id: 21,
        title: 'PLANO FAMÍLIA 4 PLUS (com seguro)',
        price: 69.90,
        multiplierDependent: 4,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 4 membros',
          'Diárias de Internação Hospitalar por Acidente ou Doença (até10 diárias de R$ 300,00 cada). O capital segurado será pago de uma única vez eserá calculado após a regulação do sinistro.',
          'Morte (R$ 5.000,00),',
          'Invalidez Permanente e Total por Acidente (R$ 5.000,00)',
          'Reembolso de Despesas com Funeral Titular (R$ 4.000,00).',
          '(Ver regras e prazos de franquia no contrato digital nopasso 3, parágrafo 6). O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38.',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos',
          'Todos estes benefícios para os 4 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeiraparcela). Valor mensal do seguro: R$ 7,35 / por vida.',
        ],
      },
    },
    {
      planName: 'plano-familia-5',
      withoutInsurance: {
        id: 20,
        title: 'PLANO FAMÍLIA 5 (sem seguro)',
        price: 74.90,
        multiplierDependent: null,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 5 membros',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 5 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela).',
        ],
      },
      insurance: {
        id: 19,
        title: 'PLANO FAMÍLIA 5 PLUS (com seguro)',
        price: 79.90,
        multiplierDependent: 5,
        priceDesc: '+ taxa de ativação de R$ 50,00',
        description: [
          'Plano para famílias com até 5 membros',
          'Diárias de Internação Hospitalar por Acidente ou Doença (até10 diárias de R$ 300,00 cada). O capital segurado será pago de uma única vez eserá calculado após a regulação do sinistro.',
          'Morte (R$ 5.000,00),',
          'Invalidez Permanente e Total por Acidente (R$ 5.000,00)',
          'Reembolso de Despesascom Funeral Titular (R$ 4.000,00).',
          '(Ver regras e prazos de franquia no contrato digital nopasso 3, parágrafo 6). O seguro é garantido pela MAPFRE SEGUROS GERAIS S.A.,CNPJ 61.074.175/0001-38.',
          'Consultas com retorno a partir de R$ 39,00 com médicosexperientes, sem filas e sem estresse na rede de clínicas do Atend Já.',
          'Desconto médio de 20% em exames na rede de clínicas do AtendJá.',
          'Descontos de até 50% em óticas, academias, lazer, educação emais de 3.000 parceiros do Vitta Med Club pelo Brasil.',
          'Desconto de até 50% em medicamentos.',
          'Todos estes benefícios para os 5 membros da família.',
          'Obs: Taxa de ativação: R$ 50,00 (na primeira parcela). Valormensal do seguro: R$ 7,35 / por vida.',
        ],
      },
    },
  ],
};
