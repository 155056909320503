import styled from 'styled-components';
import InputMask from 'react-input-mask';
import colors from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: ${colors.secondary};
  background: #F5F5F5;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%;
  height: 20%;

  background: ${colors.primary};
`;

export const HeaderLogo = styled.img`
  width: 20%;
  height: auto;
  resize: none;
  margin-left: 30px;

  @media (max-width: 320px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin-left: 2%;
  }

  @media (max-width: 600px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 801px) {
    width: 60%;
    margin-left: 2%;
  }

  @media (max-width: 1025px) {
    width: 60%;
    margin-left: 2%;
  }

`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 25px;

  @media (max-width: 320px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 801px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SidebarCard = styled.div`
  width: 25%;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid ${colors.primary};
  margin-right: 25px;

  @media (max-width: 320px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 801px) {
    width: 100%;
    margin-right: 0px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const InputContainer = styled.div`
  width: 55%;

  @media (max-width: 320px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 801px) {
    width: 100%;
    margin-top: 20px;
  }

  @media (max-width: 1025px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const PlansTitle = styled.h3`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 2px;
`;

export const PlansDescriptionList = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 15px;
  padding-left: 15px;
`;

export const PlansDescription = styled.li`
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
  color: #555;

  &::before {
    content: "• ";
    color: black;
  }
`;

export const PlansPrice = styled.span`
  font-size: 30px;
  color: ${colors.primary};
  font-weight: 700;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PlansPriceDesc = styled.p`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 30px;
  color: ${colors.primary};
`;

export const PaymentMethodText = styled.p`
  font-size: 13px;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 0.5px dashed rgba(85, 85, 85, 0.5);
`;

export const PaymentSub = styled.p`
  padding-bottom: 5px;
  font-size: 13px;
  width: 100%;

  color: #999;
`;

export const LineContainer = styled.div`
  width: ${({ width }) => width || '100%'};
  display: flex;
  margin-top: ${({ mt }) => mt || '0px'};
  margin-bottom: ${({ mb }) => mb || '0px'};
  margin-left: ${({ ml }) => ml || '0px'};
  margin-right: ${({ mr }) => mr || '0px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
`;

export const LabelInput = styled.label`
  margin-left: 5px;
  margin-right: 10px;
`;

export const InputRadius = styled.input``;

export const SubtitleInput = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  color: #4c5667;
  font-size: 14px;
  margin-bottom: 8px;

  ${({ required }) => required && `
    &::after {
      content: " *";
      color: red;
    }
  `}
`;

export const InputForm = styled.input`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;

  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const InputMaskForm = styled(InputMask)`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;

  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const SelectForm = styled.select`
  width: ${({ width }) => width || '100%'};
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 14px;
  color: rgba(0,0,0,.6);
  height: 34px;
  padding: 6px 12px;
  background: #f2f2f2;
  transition: background 300ms;
  transition: border-color 300ms;
  border: 1px solid #f2f2f2;

  &:focus {
    background: #fafafa;
    border-color: ${colors.primary};
  }
`;

export const FormTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  color: #4c5667;
  font-size: 17px;
  margin-bottom: 8px;
`;

export const ActionBotton = styled.button`
  background-color: ${colors.primary};
  color: #fff;
  border: none;
  padding:10px 20px;
`;
